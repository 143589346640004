import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import "/node_modules/flag-icons/css/flag-icons.min.css";
// import axios from 'axios';

import 'bootstrap/dist/css/bootstrap.min.css'
import '/public/menu.css'

createApp(App)
    .use(store)
    .use(router)
    .mount('#app')
