import { createRouter, createWebHistory } from 'vue-router'
import MenuView from "@/views/MenuView.vue";
const routes = [
  {
    path: '/',
    name: 'home',
    component: MenuView
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },{
    path: '/panel_login',
    name: 'admin-login',
    component: () => import('../views/admin/AdminView.vue'),
    children: [
      {
        path: "/login",
        name: 'login',
        component: () => import('../views/admin/Auth/LoginView.vue')
      },{
        path: "/reset",
        name: 'password_reset',
        component: () => import('../views/admin/Auth/LoginView.vue')
      }
    ]
  }
  // ,{
  //   path: '/panel',
  //   name: 'admin',
  //   children: [
  //     {
  //       path: "/menu",
  //       name: 'login',
  //       component: () => import('../views/admin/Auth/LoginView.vue')
  //     },{
  //       path: "/reset",
  //       name: 'password_reset',
  //       component: () => import('../views/admin/Auth/LoginView.vue')
  //     }
  //   ]
  // }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
