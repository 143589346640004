<script>
import { ref, watch } from 'vue';

export default {
  name: 'MenuView',
  setup(){
    const search_country_ref = ref(null)
    watch(search_country_ref, () => {
      console.log(search_country_ref.value.focus())
    })
    return { search_country_ref }
  },
  data: () => {return{
    country_list: [],
    search_country_list: [],
    search_country: '',
    show_search_country: false,
    catalogs: [],
  }},
  methods: {
    async load_country_data(){
      const response = await fetch('country.json')
      this.country_list = await response.json()
      this.search_country_list = this.country_list
    },
    async load_product_data(){
      const response = await fetch('product.json')
      this.catalogs = await response.json()
    },
    do_search_country() {
      this.search_country_list = []
      for (let countryIndex in this.country_list) {
        if (this.country_list[countryIndex].name.toLowerCase().startsWith(this.search_country.toLowerCase())){
          this.search_country_list.push(this.country_list[countryIndex])
        }
      }
      if (this.search_country_list.length === 0){
        this.search_country_list = this.country_list
      }
    },
    go_menu(){
      const welcome_height = document.getElementById('welcome').clientHeight
      window.scrollTo({ top: welcome_height, behavior: 'smooth' });
    },
  },
  mounted() {
    this.load_country_data()
    this.load_product_data()
    // document.addEventListener("backbutton", this.yourCallBackFunction, false);

  },
  beforeUnmount () {
    // document.removeEventListener("backbutton", this.yourCallBackFunction);
  }
}

</script>
<template>

<main class="">

<section style="height: 100vh" id="welcome">
  <article class="h-50 d-flex">
    <img class="container" style="object-fit: contain" src="https://i.pinimg.com/564x/94/12/01/941201e93dcafc47d67b845257cf9bb8.jpg" alt="logo">
  </article>
  <div class="h-25 d-flex">
    <div class="m-auto">
      <strong class="fw-bold" style="font-size: 44px">
        Menü Dilini Seçiniz
      </strong>
      <br>
      <button class="btn btn-light btn-sm border border-2" style="font-size: 26px" @click="show_search_country=true">
        <span class="fi fi-tr rounded"></span>
        <strong class="ms-2 fw-bold text-dark">Türkçe</strong>
      </button>
    </div>
  </div>
  <div class="h-25 d-flex" @click="go_menu">
      <span class="text-primary-emphasis m-auto" style="font-size: 64px">
        <i class="fa-solid fa-angle-down"></i>
      </span>
  </div>
</section>
<div v-if="show_search_country" class="position-absolute top-0 w-100 bg-light-subtle" style="height: 100vh; ">
  <div class="d-flex w-100 pt-2 px-1">
    <input @keyup="do_search_country" ref="search_country_ref" v-model="search_country" type="text" autocomplete="1" class="form-control rounded-0" placeholder="Ülke adı">
    <button class="btn btn-light border rounded-0 ms-1 " @click="show_search_country=false">
      <i class="fa-solid fa-xmark"></i>
    </button>
  </div>
  <ul v-if="country_list" class="list-unstyled overflow-y-auto p-0 m-0" style="min-height: 60vh; max-height: 80vh">
    <li class="btn bg-light-subtle d-flex w-100 rounded-0" v-for="country in search_country_list" v-bind:key="country.code">
      <div class="col-0 col-md-4"></div>
      <span :class="'fi fi-' + country.code "></span> {{ country.name }}
      <div></div>
    </li>
  </ul>
  <div v-else class="h-75 bg-light d-flex">
    <div class="m-auto">
      <div class="spinner-border" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>
  </div>
</div>
<section class="bg-light row mx-0">
  <object class="col-12 col-md-6 col-lg-3" v-for="(catalog, index) in catalogs" v-bind:key="index">
    <article class="w-100 d-flex" style="background: url('https://i.pinimg.com/564x/98/54/d2/9854d29db1e42f92b9bb119bad099ce6.jpg')">
      <div class="w-100 d-flex text-light fw-bold " :style="`background: url('${catalog.banner}') no-repeat center; object-fit: cover; height: 30vh; font-size: 44px`">
          <span class="m-auto">
            {{ catalog.name }}
          </span>
      </div>
    </article>
    <!--    <article class="h-75 overflow-y-auto" style="background: url('https://i.pinimg.com/564x/98/54/d2/9854d29db1e42f92b9bb119bad099ce6.jpg') center">-->
    <article class="h-75 overflow-y-auto py-2">
      <div class="fw-semibold text-primary-emphasis product">
        <div class="container d-flex" v-for="(product, index) in catalog.products" v-bind:key="index">
            <span class="w-100 d-flex text-center">
              {{product.name}}
            </span>
          <span class="text-end">{{ product.price }}TL</span>
        </div>
      </div>
    </article>
  </object>
</section>
<footer class="bg-dark d-flex" style="height: 20vh; background: url('https://i.pinimg.com/564x/98/54/d2/9854d29db1e42f92b9bb119bad099ce6.jpg') center;" >
  <article class="container text-light m-auto">
    - Footer -
  </article>
</footer>
  <div class="position-fixed bottom-0 end-0 d-flex">
    <div class="w-100"></div>
    <button class="btn btn-light d-flex rounded-circle shadow mb-3 me-2" style="min-width: 50px; min-height: 50px;" onclick="window.scrollTo({ top: 0, behavior: 'smooth' });">
      <i class="fa-regular fa-circle-up m-auto"></i>
    </button>
  </div>
</main>
</template>

<style scoped>

</style>
